<template lang="pug">
section.FSection(:class="classes")
  // @slot Use this slot for content
  slot
</template>

<style lang="stylus">
.FSection
  position relative

  &--marginTop
    margin-top rem(90)

    +media-down('sm')
      margin-top rem(48)

  &--marginBottom
    margin-bottom rem(90)

    +media-down('sm')
      margin-bottom rem(48)
</style>

<script setup lang="ts">
export interface FSectionProps {
  /**
   * Margins to apply to section
   */
  margins?: 'top-bottom' | 'top' | 'bottom' | 'none';
}

const props = withDefaults(defineProps<FSectionProps>(), {
  margins: 'top-bottom',
});

const classes = computed(() => ({
  'FSection--marginTop': ['top', 'top-bottom'].includes(props.margins),
  'FSection--marginBottom': ['bottom', 'top-bottom'].includes(props.margins),
}));
</script>
