<template lang="pug">
.FSectionContent(
  ref="sectionContentRef"
  :style="style"
  :class="classes"
)
  // @slot Use this slot for content
  slot
</template>

<style lang="stylus">
.FSectionContent
  position relative
  width 'calc(100% - %s)' % rem(128) // padding left/right
  margin 0 auto

  +media-down('sm')
    width 'calc(100% - %s)' % rem(48) // padding left/right

  &--default
    max-width var(--FSectionContent--width)

  &--text
    max-width rem(960)

    +media-down('md')
      max-width 80%

  &--narrow
    max-width rem(640)

  &--fitContent
    max-width max-content

    > *
      max-width 100%
</style>

<script lang="ts" setup>
export interface FSectionContentProps {
  /**
   * Width of the section content
   */
  width?: string | number;
  /**
   * Width preset for the section content:
   * - default: limits the width to the one passed in prop, with a fallback to 1312px;
   * - text: limits the width to a suited one (960px);
   * - fit-content: limits the width the child's one;
   */
  widthMode?: 'default' | 'text' | 'narrow' | 'fit-content';
}

const props = withDefaults(defineProps<FSectionContentProps>(), {
  // 1440px (max section content width) - 2 * 64px (padding left/right)
  width: 1312,
  widthMode: 'default',
  fitContent: false,
});

const sectionContentRef = ref<HTMLElement>();

const classes = computed(() => ({
  'FSectionContent--default': props.widthMode === 'default',
  'FSectionContent--text': props.widthMode === 'text',
  'FSectionContent--narrow': props.widthMode === 'narrow',
  'FSectionContent--fitContent': props.widthMode === 'fit-content',
}));

const style = computed(
  (): Style => ({
    '--FSectionContent--width': genSize(props.width),
  })
);
</script>
